import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.css']
})
export class TimelineItemComponent implements OnInit {


  @Input()
  item:any

  constructor() { }

  ngOnInit() {
  }

}
