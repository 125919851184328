import { Component, Input } from '@angular/core';
import { SidebarModule } from 'ng-sidebar';
import { FormsModule } from '@angular/forms';
import data from '../assets/content.json';
import {MatButtonModule} from '@angular/material/button';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public onChange(event):void{
    this.content = data[this.language];
    this.educationlist = this.content["education"]["items"];
    this.worklist = this.content["employment"]["items"];
  }

  scroll(el: HTMLElement){

    el.scrollIntoView(true);
    // if the window is small and the navbar is displayed,
    // scroll a little further so the top of the text is still visible
    var offset = document.getElementById("navbar").offsetHeight;
    window.scrollBy(0,-offset); // height of navbar
    this.closeNav();
  }


  language:string = "english";
  content: any  = data[this.language];
  title = this.content["title"];


  public educationlist = this.content["education"]["items"];
  public worklist = this.content["employment"]["items"];
  public opened: boolean = true;
  public mode = 'push';
  private _toggleSidebar() {
    this.opened = !this.opened;
  }

  public openNav():void {
    document.getElementById("mySidenav").style.width = "250px";
  }

  public closeNav():void {
    document.getElementById("mySidenav").style.width = "0";
  }

  public submit():void{

  }

  public pdf():void{
    var url;
    if (this.language == "english"){
      url = "../assets/TimenCelis-resume-english.pdf"
    }
    else if (this.language == "dutch"){
      url = "../assets/TimenCelis-resume-nederlands.pdf"
    }
    window.open(url, '_blank');
  }

}
